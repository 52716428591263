import * as React from "react"
import Container from '@material-ui/core/Container'
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Contact from "../contact"
import Grid from '@material-ui/core/Grid';
import facebook from "../../images/facebook.svg"
import linkedin from "../../images/linkedin.svg"
import gatsby from "../../images/gatsby.svg"



const Footer = () => {
    return (
      <Container maxWidth={false} className={classNames(style.pf_dark_grey_blue, style.pf_grey_all, style.pf_block_pads)}>
        <Container maxWidth="lg" >
          <Grid container spacing={4}>
            <Grid item xs={6} sm={3}>
              <h4>Skills</h4>

              <ul className={style.pf_footer_links}>
                {["Interactive Prototyping", "Wireframes", "User Research", "User Flows", "User Journey Maps", "Usability Tests", "A/B Tests", "Visual Design", "Roadmap Planning", "Design Systems"].sort().map((value) => (
                  <li>{value}</li>
                ))}

              </ul>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h4>Tools</h4>
              <ul className={style.pf_footer_links}>
                {["Sketch", "Figma", "Adobe Photoshop", "Adobe XD", "Axure RP", "Invision", "Fusion 360", "Adobe Illustrator"].sort().map((value) => (
                  <li>{value}</li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={6} sm={3}>
              <h4>Technologies</h4>
              <ul className={style.pf_footer_links}>
                {["HTML", "CSS", "React", "Javascript", "Android SDK", "Java"].sort().map((value) => (
                  <li>{value}</li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={6} sm={3} >
              <div className={style.footericons}>
              <span className={style.gatsbyjs}>
                Built with <img src ={gatsby}/>
              </span>  
              <div className={style.pf_social_icons}> 
              <span>
                <a href="https://www.linkedin.com/in/vinayshanthagiri" target="_blank">
                <img src={facebook} />
                </a>
              </span>
              <span>
              <a href="https://www.linkedin.com/in/vinayshanthagiri" target="_blank">
                <img src={linkedin} />
                </a>
              </span>
              </div> 
              </div>
            </Grid>
          </Grid>

        </Container>

      </Container>
      
      
      
    )
}


export default Footer
// extracted by mini-css-extract-plugin
export var footericons = "styles-module--footericons--XcQ22";
export var gatsbyjs = "styles-module--gatsbyjs--KqPWk";
export var pf_80_container_floating = "styles-module--pf_80_container_floating--Cs5J7";
export var pf_block_pads = "styles-module--pf_block_pads--e6uZE";
export var pf_blue_card = "styles-module--pf_blue_card--cLVSL";
export var pf_button_dark_blue = "styles-module--pf_button_dark_blue--GwbmR";
export var pf_card_content_blue = "styles-module--pf_card_content_blue--RGls1";
export var pf_dark_grey_blue = "styles-module--pf_dark_grey_blue--crrtZ";
export var pf_grey_all = "styles-module--pf_grey_all--Jtolg";
export var pf_round_bt = "styles-module--pf_round_bt--nHoWF";
export var pf_social_icons = "styles-module--pf_social_icons--cUlCQ";
import * as React from "react"
import * as style from "./styles.module.scss"

const Sectionheaderdark = ({label, caption, children}) => {
    return (
        <div className={style.pf_section_wrapper_dark}>
        <h4>{caption}</h4>
        <h1>{label}</h1>
        <p className={style.pf_section_header_splint}></p>
        {children}
        </div>
    )
}


export default Sectionheaderdark
import React from 'react';
import {useState} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import RightAlt from '@material-ui/icons/ArrowRightAltSharp';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Tabpanel from "../tabpanel"


export default function Slidepanel() {
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
      });

   
    return (
        <div>

<SlidingPane
        className={style.pf_static_menu}
        overlayClassName={style.pf_static_menu_over}
        isOpen={state.isPaneOpen}
        title="My Skills, Tools & Technologies"
       hideHeader={true}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
        width="30vw"
      >
       <Tabpanel></Tabpanel>
      
      </SlidingPane >
                <Button  onClick={() => setState({ isPaneOpen: true })} className={classNames(style.pf_round_bt, style.pf_button_white, style.recruiter)} variant="contained" color="primary">My Skillset</Button>



        </div>
    );
}

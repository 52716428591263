import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'
import Topnav from "../top_nav/index"
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MobMenu from "../mobmenu";
import { makeStyles } from '@material-ui/core/styles';
import mainlogo from "../../images/logo_port_slim.svg"
import { Link } from "gatsby"
import { useScrollTrigger } from "@material-ui/core";
import classNames from 'classnames';
import * as style from "./styles.module.scss"
import { useState, useRef, useEffect } from "react";











function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ threshold: 300 });
    // console.log(" i am called")
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        <div className={style.pf_slider_top_nav}>
          {children}
        </div>
      </Slide>
    );
  }

  

const Fullnav = (props) => {
  const [navbar, setNav] = useState(false)

  const changeBackground = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNav(true);
    }
    else setNav(false);
  }
  useEffect(() => {
    window.addEventListener('scroll', changeBackground)
  });
    return (
        <div>
        <div >
          <HideOnScroll {...props}>
            <div className={navbar ? (style.pf_navbarx, style.pf_navbarx_active) : (style.pf_navbarx)}>
              <div className={style.pf_navbar}>

                <AppBar className={style.pf_noshadow} color="transparent">
                  <Container >
                    <Topnav></Topnav>
                  </Container>
                </AppBar>
              </div>
              <div className={style.pf_appbar}>
                <AppBar className={classNames(style.pf_dark_grey, style.pf_noshadow)} >
                  <Container maxWidth={false} className={style.pf_80_width}>
                    <Toolbar variant="dense" className={style.pf_menu_place}>

                      <IconButton edge="start" >
                        <Link to="/"><img src={mainlogo} /></Link>
                      </IconButton>
                      <MobMenu></MobMenu>
                      {/* <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon1 style={{ color: "#000" }} />
                      </IconButton> */}
                    </Toolbar>
                  </Container>
                </AppBar>
              </div>
            </div>
          </HideOnScroll>
        </div>
      </div>
      
    )
}


export default Fullnav



import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import RightAlt from '@material-ui/icons/ArrowRightAltSharp';
import MenuIcon1 from "@material-ui/icons/Menu"
import IconButton from '@material-ui/core/IconButton';




const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function MobMenu() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            
            <List className={style.pf_mobMenu}>
                <ul>
            <li><a href="/#home">Home</a></li>
            <li><a href ="/#current"> Work</a></li>
            <li><a href ="/#past">Projects</a></li>
            <li><a href ="/#journey">Journey</a></li>
            {/* <li><a href ="#motDesigning">When not Designing</a></li> */}
            <li><a href ="/#connect">Connect</a></li>
            </ul>
            </List>
            
        </div>
    );

    return (
        <div>


            {/* <Button onClick={toggleDrawer("right", true)} className={classNames(style.pf_round_bt, style.pf_button_white)} variant="contained" color="primary">
                My Skills, Tools & Technologies<RightAlt className={style.pf_icon_clearance}></RightAlt>
            </Button> */}
            <IconButton onClick={toggleDrawer("right", true)} edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon1 style={{ color: "#000" }} />
                      </IconButton>
            <Drawer anchor="right" open={state["right"]} onClose={toggleDrawer("right", false)} >
                {list("right")}
            </Drawer>


        </div>
    );
}

import * as React from "react"
import {useState} from "react"
import Container from '@material-ui/core/Container'
import { StaticImage } from "gatsby-plugin-image"
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import RightAlt from '@material-ui/icons/ArrowRightAltSharp';
import mainlogo from "../../images/logo_port_slim.svg"
import Sidepanel from "../sidepanel/index"
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Slidepanel from "../slidepanel"
import { Link } from "gatsby"


const Topnav = () => {
 
    return (
   
      <div className={classNames(style.pf_top_nav_wrapper)}>
        <div>
          <ul>
            <li className={style.pf_main_logo}>
           <Link to="/"><img src={mainlogo}/></Link> 
            </li>
            
          </ul>
        </div>
        <div>
          <ul>
          {/* <li className={style.pf_blue_pipe}>|</li> */}
            {/* <li><a href ="/#current">Work</a></li> */}
            <li><a href ="/#past">Case Studies</a></li>
            <li><a href ="/#journey">Journey</a></li>
            {/* <li><a href ="#motDesigning">When not Designing</a></li> */}
            <li><a href ="/#connect">Connect</a></li>
            <li><Slidepanel ></Slidepanel></li>
          </ul>
          
          {/* <Sidepanel></Sidepanel> */}
          {/* <Button className={classNames(style.pf_round_bt, style.pf_button_white)} variant="contained" color="primary">My Skills, Tools & Technologies<RightAlt className={style.pf_icon_clearance}></RightAlt></Button> */}
        </div>
      </div>
     
    )
}


export default Topnav
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button'
import * as style from "./styles.module.scss"
import classNames from 'classnames'


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classNames(style.pf_modal_form, classes.paper)}>
     
     
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf8n5v2cRD3I_eGd-ga4Uwx6JJPV_IEZkptBR_2HUISo8bdQw/viewform?embedded=true" width="100%" height="950px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

    </div>
  );

  return (
    <div>
     
      <Button target="_blank" href="https://www.linkedin.com/in/vinayshanthagiri"className={ classNames(style.pf_round_bt, style.pf_button_dark_blue)} variant="contained" color="primary">Connect with me on Linkedin</Button>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal> */}
    </div>
  );
}

// extracted by mini-css-extract-plugin
export var pf_80_container_floating = "styles-module--pf_80_container_floating--57lbJ";
export var pf_blue_card = "styles-module--pf_blue_card--jUKff";
export var pf_button_dark_blue = "styles-module--pf_button_dark_blue--zf-z-";
export var pf_button_white = "styles-module--pf_button_white--AkLMr";
export var pf_card_content_blue = "styles-module--pf_card_content_blue--n7lRn";
export var pf_icon_clearance = "styles-module--pf_icon_clearance--A9tyn";
export var pf_modal_form = "styles-module--pf_modal_form--jx4GN";
export var pf_round_bt = "styles-module--pf_round_bt--LF+mr";
export var pf_static_menu = "styles-module--pf_static_menu--H697L";
export var pf_static_menu_over = "styles-module--pf_static_menu_over--Kkzmj";
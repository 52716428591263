import * as React from "react"
import * as style from "./styles.module.scss"

const Sectionheaderlight = ({ label, caption, children, left, home }) => {
    return (
        <div className={left ? (style.pf_section_wrapper_light, style.sectionleft) : (style.pf_section_wrapper_light)}>
            <h4>{caption}</h4>
            <h1>{label}</h1>
            {home?
             <></>:
                <p className={style.pf_section_header_splint}></p>
            }
            {children}
        </div>
    )
}


export default Sectionheaderlight
import * as React from "react"
import Container from '@material-ui/core/Container'
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import Sectionheaderlight from "../typography/section_headers/light"
import AlbumIcon from '@material-ui/icons/Album';




const CaseHeader = ({ label, description, high1, high2, high3, img, gradientdark, home, button, children }) => {
  return (
    <Container maxWidth={false} disableGutters={true} className={gradientdark ? (style.graidentdark) : (style.graidentback)}>
      {children}
      <Container >
        <div className={(home? style.casegridhome: style.casegrid)}>
          <div className={(home ? style.casedetailshome : style.casedetails)}>
            <Sectionheaderlight left label={label} home={home}>
              <div className={style.casedescription}>
                <p>
                  {description}
                </p>
              </div>
            </Sectionheaderlight>
            {high1
              ? (
                <div>
                  <h3>Highlights</h3>
                  <ul>
                    <li className={style.hightext}><AlbumIcon className={style.blue} /><div>{high1}</div></li>
                    <li className={style.hightext}><AlbumIcon className={style.blue} /><div>{high2}</div></li>
                    <li className={style.hightext}><AlbumIcon className={style.blue} /><div>{high3}</div></li>

                  </ul>
                </div>
              )
              : <></>
            }
            {button ?
              <div className={style.btdesktop}>
                <Button className={classNames(style.pf_round_bt, style.pf_button_dark_blue)} variant="contained" color="primary">More Details</Button>
              </div> : <></>
            }
          </div>
          <div className={style.caseimg}>
            <img src={img} />
          </div>
          {button ?
            <div className={style.btmobile}>
              <Button className={classNames(style.pf_round_bt, style.pf_button_dark_blue)} variant="contained" color="primary">More Details</Button>

            </div> : <></>
          }
        </div>


      </Container>
    </Container>


  )
}


export default CaseHeader
// extracted by mini-css-extract-plugin
export var blue = "styles-module--blue--dv6mQ";
export var btdesktop = "styles-module--btdesktop--iOJMp";
export var btmobile = "styles-module--btmobile--sDaDW";
export var casedescription = "styles-module--casedescription--qKVEg";
export var casedetails = "styles-module--casedetails--NHxEB";
export var casedetailshome = "styles-module--casedetailshome--g8wWp";
export var casegrid = "styles-module--casegrid--GzW+n";
export var casegridhome = "styles-module--casegridhome--o98F5";
export var caseimg = "styles-module--caseimg--FVH9c";
export var graidentback = "styles-module--graidentback--rEyDX";
export var graidentback1 = "styles-module--graidentback1--elrE+";
export var graidentdark = "styles-module--graidentdark--GLVxt";
export var hightext = "styles-module--hightext--JJpqe";
export var pf_button_dark_blue = "styles-module--pf_button_dark_blue--cGToN";
export var pf_round_bt = "styles-module--pf_round_bt--hon9k";